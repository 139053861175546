import React, { Component } from 'react'
import { FacebookProvider, Page } from 'react-facebook'
//import { MdGraphicEq } from 'react-icons/md'

export default class fbevent extends Component {
  render () {
    return (
      <div className='fb-wrapper'>
{/*         <h1>- NEXT SHOW -</h1>

        <h3>Stay tuned!</h3> */}
{/*         <h1>
          <MdGraphicEq className='social-icon' />
        </h1> */}
{/*         <FacebookProvider appId='284904308869270'>
          <Page
            href='https://www.facebook.com/thestagequebec'
            tabs='timeline'
            width='1200'
          />
        </FacebookProvider> */}
      </div>
    )
  }
}
