import React from 'react'

import Layout from '../components/layout'
// import Player from '../components/player'
import FBEvent from '../components/fbevent'
import BlogRoll from '../components/BlogRoll'

const IndexPage = () => (
  
  <Layout herotoggle={true} fullwidth={false}>
            <div className="content">
              <BlogRoll />
            </div>
    <FBEvent />
    {/* <Player /> */}
  </Layout>
)

export default IndexPage
